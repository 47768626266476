.signup-wrapper {
    margin-top: 65px;
  }
  
.signup-container {
    max-width: 68%;
    margin: auto;
    border: 1px solid #eee9e9;
    border-radius: 2px;
    padding: 25px 30px;
    margin-bottom: 20px;
}
  
.signup-container h1 {
  text-align: start;
  margin-left: 0px;
  position: relative;
  margin-bottom: 46px;
  font-family: emoji;
}
.signup-container h1::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 78px;
    height: 3px;
    border-radius: 28px;
    background-color: red;
}


  .form-wrapper {
    margin-bottom: 15px;
  }
  .form-fields {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: none;
    background-color: #f4f3f3;
    height: 60px;
}
.btn-register {
  background-color: #013D73;
  color: white;
  padding: 5px 28px;
  margin: 12px 0px;
  font-size: 14px;
}

.btn-register:hover {    
      background-color: #0f5089;
}

  .row-signup,
  .row-phone,
  .row-apply,
  .row-apply2 {
    display: flex;
    flex-wrap: wrap;
  }
  
  .row-signup .form-group {
    width: calc(50% - 15px);
    
  }
 
  
  .row-apply .form-group,
  .row-phone .form-group {
    width: calc(33.3% - 9px);
    
  }

  
  .row-apply2 .form-group {
    width: calc(25% - 7px);
    
  }
  
  
  .required-star {
    color: red;
}

.row-banner {
  display: flex;
  background: linear-gradient(to bottom, #f3f3f3, #ffffff);
  margin-top: 1px;
}
.col-banner1 {
  width: 33%;
  background-color: #013D73;
  color: white;
  margin: 0px 13px;
  position: relative;
}

.col-banner1 h6 {
  font-size: 45px;
  line-height: 0.6;
  font-weight: bolder;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.col-banner2 {
    width: 70%;
}

.banner {
    width: 90%;
    overflow: hidden; 
}

.banner img {
  width: 99%;
  height: 253px;
  display: block;
  margin: 0px;
  object-fit: cover;
}

  @media (max-width: 768px) {
    .row-signup .form-group,
    .row-apply .form-group,
    .row-apply2 .form-group,
    .row-phone .form-group {
      width: 100%;
    }
    .col-banner1 {
        display: none;
    }
    .col-banner2 {
        width: 100%;
    }
    .signup-container {
      max-width: 90%;
    }
   
  }

  .react-datepicker__input-container input {
    width: calc(100% - 20px);
    padding: 10px;
    font-size: 16px;
    border: none;
    background-color: #f4f3f3;
    height: 60px;
}

.react-datepicker-popper {
    z-index: 9999;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}
.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 105%;
}

btn-gray {
  background-color: gray;
  border: none;
}