/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.rc-notification {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    position: fixed;
    z-index: 30000 !important;
    width: 384px;
    max-width: calc(100vw - 32px);
    margin-right: 24px;
    right: 0 !important;
    left: auto !important;
    top: 25px !important;
}

.rc-notification-topLeft,
.rc-notification-bottomLeft {
    margin-right: 0;
    margin-left: 24px;
}

.rc-notification-topLeft .rc-notification-fade-enter.rc-notification-fade-enter-active,
.rc-notification-bottomLeft .rc-notification-fade-enter.rc-notification-fade-enter-active,
.rc-notification-topLeft .rc-notification-fade-appear.rc-notification-fade-appear-active,
.rc-notification-bottomLeft .rc-notification-fade-appear.rc-notification-fade-appear-active {
    -webkit-animation-name: NotificationLeftFadeIn;
    animation-name: NotificationLeftFadeIn;
}

.rc-notification-close-icon {
    font-size: 14px;
    cursor: pointer;
}

.rc-notification-notice {
    position: relative;
    margin-bottom: 16px;
    padding: 16px 24px;
    overflow: hidden;
    line-height: 1.5;
    background: #fff;
    border-radius: 4px;
    -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.rc-notification-notice-message {
    display: inline-block;
    /*margin-bottom: 8px;*/
    margin-top: 5px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    line-height: 24px;
    width: 100px;
}

.rc-notification-notice-message-single-line-auto-margin {
    display: block;
    width: calc(384px - 24px * 2 - 24px - 48px - 100%);
    max-width: 4px;
    background-color: transparent;
    pointer-events: none;
}

.rc-notification-notice-message-single-line-auto-margin::before {
    display: block;
    content: '';
}

.rc-notification-notice-description {
    font-size: 14px;
}

.rc-notification-notice-closable .rc-notification-notice-message {
    padding-right: 24px;
}

.rc-notification-notice-with-icon .rc-notification-notice-message {
    margin-bottom: 4px;
    margin-left: 48px;
    font-size: 16px;
}

.rc-notification-notice-with-icon .rc-notification-notice-description {
    margin-left: 48px;
    font-size: 14px;
}

.rc-notification-notice-icon {
    position: absolute;
    margin-left: 4px;
    font-size: 24px;
    line-height: 24px;
}

.icon {
    /*font-size: 30px;*/
    height: 30px;
}

.icon.rc-notification-notice-icon-success {
    color: #52c41a;
}

.icon.rc-notification-notice-icon-info {
    color: #1890ff;
}

.icon.rc-notification-notice-icon-warning {
    color: #faad14;
}

.icon.rc-notification-notice-icon-error {
    color: #f5222d;
}

.rc-notification-notice-close {
    position: absolute;
    top: 20px;
    right: 22px;
    color: rgba(0, 0, 0, 0.45);
    outline: none;
}

.rc-notification-notice-close:hover {
    color: rgba(0, 0, 0, 0.67);
}

.rc-notification-notice-btn {
    float: right;
    margin-top: 16px;
}

.rc-notification .notification-fade-effect {
    -webkit-animation-duration: 0.24s;
    animation-duration: 0.24s;
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.rc-notification-fade-enter,
.rc-notification-fade-appear {
    opacity: 0;
    -webkit-animation-duration: 0.24s;
    animation-duration: 0.24s;
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.rc-notification-fade-leave {
    -webkit-animation-duration: 0.24s;
    animation-duration: 0.24s;
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.rc-notification-fade-enter.rc-notification-fade-enter-active,
.rc-notification-fade-appear.rc-notification-fade-appear-active {
    -webkit-animation-name: NotificationFadeIn;
    animation-name: NotificationFadeIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}

.rc-notification-fade-leave.rc-notification-fade-leave-active {
    -webkit-animation-name: NotificationFadeOut;
    animation-name: NotificationFadeOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}

@-webkit-keyframes NotificationFadeIn {
    0% {
        left: 384px;
        opacity: 0;
    }
    100% {
        left: 0;
        opacity: 1;
    }
}

@keyframes NotificationFadeIn {
    0% {
        left: 384px;
        opacity: 0;
    }
    100% {
        left: 0;
        opacity: 1;
    }
}

@-webkit-keyframes NotificationLeftFadeIn {
    0% {
        right: 384px;
        opacity: 0;
    }
    100% {
        right: 0;
        opacity: 1;
    }
}

@keyframes NotificationLeftFadeIn {
    0% {
        right: 384px;
        opacity: 0;
    }
    100% {
        right: 0;
        opacity: 1;
    }
}

@-webkit-keyframes NotificationFadeOut {
    0% {
        max-height: 150px;
        margin-bottom: 16px;
        padding-top: 16px 24px;
        padding-bottom: 16px 24px;
        opacity: 1;
    }
    100% {
        max-height: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
        opacity: 0;
    }
}

.rc-notification-notice-message {
    display: inline-block;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, .85);
    font-size: 16px;
    line-height: 24px;
    width: 100%;
}

.rc-notification-notice-description {
    font-size: 14px;
    width: 100%;


}

.rc-box {
    position: relative;
    padding-left: 14%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes NotificationFadeOut {
    0% {
        max-height: 150px;
        margin-bottom: 16px;
        padding-top: 16px 24px;
        padding-bottom: 16px 24px;
        opacity: 1;
    }
    100% {
        max-height: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
        opacity: 0;
    }
}
